import React from 'react';

import Layout from '@/components/layout/public';
import Hero from '@/components/public/hero';
import Callout from '@/components/public/callout';
import Pricing from '@/components/public/pricing';
import Faq from '@/components/public/faq';
import SEO from '@/components/seo';

const IndexPage = () => (
	<Layout>
		<SEO/>
		<Hero style={{ marginBottom: 150 }}/>
		<Pricing style={{ marginBottom: 50 }}/>
		<Callout style={{ marginBottom: 50 }}/>
		<Faq style={{ marginBottom: 50 }}/>
	</Layout>
);

export default IndexPage;
