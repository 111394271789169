import * as React from 'react';

import { navigate } from 'gatsby';
import styled from 'styled-components';
import { CheckCircleOutlined, SafetyCertificateOutlined, StopOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Tabs, Row, Col, Button } from 'antd';

import Rules from './static/rules.svg';
import Unblock from './static/unblock.svg';
import Speedup from './static/speedup.svg';
import Block from './static/protect.svg';

const Wrapper = styled(Tabs)`
	h3 {
		color: black;
		text-align: left;
		font-size: 2.3rem;
		line-height: 2.8rem;
		font-weight: 800;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	
	img {
		width: auto;
		height: auto;
		position: absolute;
		top: 0;  
		bottom: 0;  
		left: 0;  
		right: 0;  
		margin: auto;
		max-height: 300px;
	}
	
	p {
		font-size: 1.1rem;
		min-height: 220px;
		text-align: justify;
	}
	
	.morebtn {
		height: 60px;
		font-size:1.3rem;
		padding: 0 25px;
		border-radius: 0;
		margin-top: 10px;
		font-weight: 800;
	}
	
	.tab {
		color: var(--color-primary);
		font-size: 1.6rem;
		font-weight: 800;
		text-transform: uppercase;
		width: 100%;
	}
	
	.media {
		background-size: 70%!important;
	}
	
	@media (max-width: 480px) {
		h3 {
			font-size: 1.3rem;
			line-height: 1.8rem;
		}
		
		.tab {
			font-size: 1rem;
		}
		
		.morebtn {
			height: 45px;
			font-size:1rem;
		}
	}
`;

interface Props {

}

interface State {

}

export default class TabbedFeatures extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {
	render() {
		return (
            <Wrapper defaultActiveKey="0" size={'large'}>
                <Tabs.TabPane tab={<span className={'tab'}>
                                <StopOutlined style={{ color: '#2F57E4' }} />Block</span>} key="0">
                    <Row gutter={48} type="flex">
                        <Col lg={12} style={{ height: '100%' }}>
                            <h3>Block ads and other unwanted content</h3>
                            <p>
                                Let's be honest, ads are the very reason that started this whole array of tools designed
                                to block them. Invasive and intrusive they want to get more and more of your time and
                                attention. Your online experience always gets interrupted by ads and other type of content
                                that you did not ask for. <b>With DnsAdBlock you can enjoy a clean experience, safe from
                                ads and many other annoyances.</b>
                            </p>

                            <Button className={'morebtn'} block={true} type={'primary'} size={'large'}
                                    onClick={() => navigate('/features')}>
                                Find out more
                            </Button>
                        </Col>

                        <Col lg={12} className={'media'} style={{
                            background: `transparent url(${Block}) no-repeat center center`,
                        }}/>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span className={'tab'}>
                                <CheckCircleOutlined style={{ color: '#2F57E4' }} />Unblock</span>} key="1">
                    <Row gutter={48} type="flex">
                        <Col lg={12} style={{ height: '100%' }}>
                            <h3>Bypass geo-restricted content</h3>
                            <p>
                                Many websites are starting to deny access to their content by using DNS queries to
                                determine your location. A DNS detection is harder to fake with VPNs and proxies
                                because their detection system does not rely on your outgoing IP address to figure out
                                your physical location. <b>DnsAdBlock allows you to pick a DNS server from over 50
                                locations where we have datacenters.</b>
                            </p>

                            <Button className={'morebtn'} block={true} type={'primary'} size={'large'}
                                    onClick={() => navigate('/features')}>
                                Find out more
                            </Button>
                        </Col>

                        <Col lg={12} className={'media'} style={{
                            background: `transparent url(${Unblock}) no-repeat center center`,
                        }}/>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span className={'tab'}>
                        <SafetyCertificateOutlined style={{ color: '#2F57E4' }} />Protect</span>} key="2">
                    <Row gutter={48} type="flex">
                        <Col lg={12} style={{ height: '100%' }}>
                            <h3>Guard yourself against malicious content</h3>
                            <p>
                                This is more than an Ad blocker. We have well over 1.5M domains in our block list and we're
                                constantly adding more. These domains are stacked into groups that can be enabled or
                                disabled at will. The filtering and protection takes place on our servers without any
                                software being installed on your devices. <b>We're &quot;on the wire&quot; - between your
                                device and the unwanted content.</b>
                            </p>

                            <Button className={'morebtn'} block={true} type={'primary'} size={'large'}
                                    onClick={() => navigate('/features')}>
                                Find out more
                            </Button>
                        </Col>

                        <Col lg={12} className={'media'} style={{
                            background: `transparent url(${Rules}) no-repeat center center`,
                        }}/>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span className={'tab'}>
                    <LegacyIcon type="thunderbolt" theme={'filled'} style={{ color: '#2F57E4' }}/>Save bandwidth</span>} key="3">
                    <Row gutter={48} type="flex">
                        <Col lg={12} style={{ height: '100%' }}>
                            <h3>Speed-up your browsing experience</h3>
                            <p>
                                Since we block every request at a DNS level, the unwanted content cannot reach your
                                device. Many such websites are running CPU intensive applications to make their
                                content visually appealing. Getting rid of it will make your device faster, consuming
                                less resources and increasing your battery time.
                            </p>

                            <Button className={'morebtn'} block={true} type={'primary'} size={'large'}
                                    onClick={() => navigate('/features')}>
                                Find out more
                            </Button>
                        </Col>

                        <Col lg={12} className={'media'} style={{
                            background: `transparent url(${Speedup}) no-repeat center center`,
                        }}/>
                    </Row>
                </Tabs.TabPane>
            </Wrapper>
        );
	}
}
