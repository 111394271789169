import * as React from 'react';

import styled from 'styled-components';
import { Card, Row, Col } from 'antd';
// @ts-ignore
import TextLoop from 'react-text-loop';

import Content from '../content';
import TabbedFeatures from './tabbed-features';

const Wrapper = styled.div`

`;

const Heading = styled.div`
	background: var(--color-accent);
`;

const FeaturesCard = styled(Card)`
	margin-top: -100px;
	box-shadow: 0 4px 18px 0 rgba(0, 0, 0, .2);

	@media (max-width: 480px) {
		box-shadow: none;

		.ant-card-body {
			padding: 0;

			.ant-tabs-tabpane {
				padding: 0 24px 24px 24px;
			}
		}
	}
`;

const FeaturedTabsContent = styled(Content)`
	.inner {
		@media (max-width: 480px) {
			padding: 0 !important;
		}
	}
`;

const SquareBox = styled.div`
	background: black;
	color: white;
	width: 350px;
	margin: 0 auto;
	font-size: 3.3rem;
	font-weight: 800;
	text-align: center;
	vertical-align: middle;
	box-shadow: 0 1px 18px 0 rgba(0, 0, 0, .5);

	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}

	.inner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;

		div.inner-wrap {
			display: inline-block;
			vertical-align: middle;
			line-height: normal;
		}
	}

	div {
		&.kw {
			color: #4DECDB;
		}
	}

	@media (max-width: 990px) {
		margin-top: 60px;
	}

	@media (max-width: 480px) {
		display: none;
	}
`;

const HeroContent = styled(Content)`
	background: transparent;
	padding: 150px 0 200px 0;

	h1 {
		color: black;
		text-align: left;
		font-size: 2.4rem;
		font-weight: 800;
		margin: 0 auto;
		max-width: 900px;
	}

	@media (max-width: 990px) {
		padding: 0 0 200px 0;

		h1 {
			font-size: 2.3rem;
			text-align: center;
			margin-bottom: 20px;
			padding-top: 80px;
		}
	}

	@media (max-width: 480px) {
		h1 {
			font-size: 2rem;
		}
	}

	@media (max-width: 480px) {
		padding: 0 0 150px 0;

		h1 {
			font-size: 1.6rem;
			text-align: center;
			margin-bottom: 20px;
			padding-top: 20px;
		}
	}
`;

interface Props {

}

interface State {

}

export const TabbedFeaturesComponent = () => (
	<FeaturedTabsContent style={{ background: 'transparent', margin: '0 auto', width: '100%' }}>
		<FeaturesCard>
			<TabbedFeatures/>
		</FeaturesCard>
	</FeaturedTabsContent>
);

export default class Hero extends React.Component<Props & React.HTMLAttributes<HTMLDivElement>, State> {

	render() {
		return <Wrapper {...this.props}>
			<Heading>
				<HeroContent>
					<Row gutter={20}>
						<Col md={24} lg={12}>
							<div style={Object.assign({}, {
								display       : 'flex',
								alignItems    : 'center',
								justifyContent: 'center',
								height: '100%'
							})}>
								<div style={{ width: '100%', textAlign: 'center' }}>
									<h1>
										Block ads, malware, adult and other unwanted
										content with our premium DNS servers
									</h1>
								</div>
							</div>

						</Col>

						<Col xs={24} lg={12}>
							<SquareBox>
								<div className={'inner'}>
									<div className={'inner-wrap'}>
										<div>Block</div>
										<TextLoop
											mask={true}
											springConfig={{ stiffness: 999, damping: 20 }}
										>
											<div className={'kw'}>Ads</div>
											<div className={'kw'}>Crypto</div>
											<div className={'kw'}>Adult</div>
											<div className={'kw'}>Tracking</div>
											<div className={'kw'}>Malware</div>
											<div className={'kw'}>Phishing</div>
											<div className={'kw'}>Fake News</div>
										</TextLoop>
									</div>
								</div>
							</SquareBox>
						</Col>
					</Row>
				</HeroContent>
			</Heading>

			<TabbedFeaturesComponent/>
		</Wrapper>;
	}
}
